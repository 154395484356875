import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { toast } from "react-toastify";
import {filesize} from 'filesize'

import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { isEmpty, get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  GET_ALL_PERSONNEL,
  UPLOAD_PERSONNEL_CSV_DATA,
} from "../../../../store/types";
import { SHOW_ROWS } from "../../../../constant/constant";
import PersonnelGrid from "./personnelGrid";
import "@progress/kendo-theme-default/dist/all.css";
import "../../../../style/sass/main.scss";
import { downloadCsvApi } from "../../../../apis";
import { Error } from "@progress/kendo-react-labels";
import { csvFileReader } from "../../../../utils/csvFileReader";
import { entitiesName, permission } from "../../../../utils/Constants";
import ProcessingLoader from "../../../../components/processingLoader/";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Breadcrumb from "../../../../components/breadcrumb";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import { use } from "i18next";

const List = (props) => {
  const { t } = useTranslation();
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL_PERSONNEL =
    process.env.REACT_APP_SCAI_API_BASEURL_PERSONNEL;

    const locationConfiguration = JSON.parse(
      localStorage.getItem("configuration")
    )
      ? JSON.parse(localStorage.getItem("configuration")).entities
          .locationHierarchy
      : {};
    const productConfiguration = JSON.parse(localStorage.getItem("configuration"))
      ? JSON.parse(localStorage.getItem("configuration")).entities
          .productHierarchy
      : {};
    const demandPlannerConfiguration = JSON.parse(
      localStorage.getItem("configuration")
    )
      ? JSON.parse(localStorage.getItem("configuration")).entities
          .demandPlannerHierarchy
      : {};
    const salesmanConfiguration = JSON.parse(
      localStorage.getItem("configuration")
    )
      ? JSON.parse(localStorage.getItem("configuration")).entities
          .salesmanHierarchy
      : {};

      const marketingHierarchyConfiguration = JSON.parse(
        localStorage.getItem("configuration")
      )
        ? JSON.parse(localStorage.getItem("configuration")).entities
            .marketingHierarchy
        : {};



      

  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(permission.personnelAddEdit);
  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");
  const [allCheckboxId, setAllCheckboxId] = useState([]);
  const [childData, setChildData] = useState([]);
  const [hideShowList, setHideShowList] = useState([]);
  const [searchColumns, setSearchColumns] = useState("");
  const [updateflag, setUpdateflag] = useState(false);
  const [newallCheckboxId, setNewallCheckboxId] = useState([]);
  const hierarchyName = "demandPlanner";
  const hierarchyNameExtra = "salesman";
  const notRequiredColumns = JSON.parse(sessionStorage.getItem('personal'));
  let strNRC;
  if (notRequiredColumns != null) {
    strNRC = notRequiredColumns.join(",");
  }

  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file]);
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));
  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV("");
    }
  }, [visible]);
  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const toggleDialog = () => {
    dispatch({
      type: "personnel/personnelCSVUploadInitiate",
      payload: { csvUpload: false, loader: false },
    });
    setVisible(!visible);
  };

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    personnelId: "0",
    tenantId: tenantId,
  };

  useEffect(() => {
    if (!refresh) {
      dispatch({ type: GET_ALL_PERSONNEL, payload: body });
    }
  }, [rowLength, pageIndex, sortValue]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setPageIndex(1);
    dispatch({ type: GET_ALL_PERSONNEL, payload: { ...body, pageIndex: 1 } });
  };

  const handleAdvanceSearch = (data) => {
    body.advanceSearchValue = data;
    body.buttonStatus = true;
    setPageIndex(1);
    dispatch({ type: GET_ALL_PERSONNEL, payload: { ...body, pageIndex: 1 } });
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({ type: GET_ALL_PERSONNEL, payload: { ...body, pageIndex: 1 } });
  };

  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_PERSONNEL, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

  const isLoading = useSelector((state) => state.PersonnelReducer.loader);
  const personnel = useSelector((state) => state.PersonnelReducer.personnel);
  const personnelDetails = get(personnel, "entities", "");
  const totalElement = get(personnel, "totalElements", "");
  const pageSize = get(personnel, "pageSize", "");
  const csvUpload = useSelector((state) => state.PersonnelReducer.csvUpload);
  const csvUploadResponse = useSelector(
    (state) => state.PersonnelReducer.csvUploadResponseData
  );
  const csvUploadErrorResponse = useSelector(
    (state) => state.PersonnelReducer.csvUploadError
  );
  const csvUploadError = useSelector(
    (state) => state.PersonnelReducer.csvUploadError
  );

  useEffect(() => {
    if (csvUploadError) {
      setVisible(false);
      toast.error(t(`${csvUploadError.message}`),
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
  }, [csvUploadError])

  const personnelDataDetails =
    personnelDetails &&
    personnelDetails.map((item) => {
      const items = { ...item };
      items.channel_name =
        Object.keys(item?.channel ?? {}).length > 0
          ? item?.channel?.channel_name
          : "";
      //     ? item?.channel?.channel_description
      items.node_code = item?.node?.node_code ?? "";


      // if (
      //   typeof marketingHierarchyConfiguration?.marketingHierarchyNoOfLevels ===
      //     "number" &&
      //   Number(marketingHierarchyConfiguration?.marketingHierarchyNoOfLevels) > 0
      // ) {
      //   console.log("yesif");
      //   for (
      //     let index = 1;
      //     index <= marketingHierarchyConfiguration?.marketingHierarchyNoOfLevels;
      //     index++
      //   ) {
      //     items[marketingHierarchyConfiguration[`marketingHierarchyLevel${index}`]] =
      //     item[`marketingHierarchyLevel${index}`];
      //     // items[`emailmarketingHierarchyLevel${index}`] =
      //     // marketingHierarchyConfiguration[`marketingHierarchyLevel${index}`] + "Email Address";
         
      //     delete items[[`marketingHierarchyLevel${index}`]];
      //     // delete items[`emailmarketingHierarchyLevel${index}`]
         
      //   }
      // }
      items.node = item?.node?.node ?? "";
      items.sku_code = item?.sku?.sku_code ?? "";
      items.sku_name = item?.sku?.sku_name ?? "";
      return items;
    });

  useEffect(() => {
    if (csvUploadErrorResponse) {
      setVisible(false);

      toast.error(t(`${csvUploadErrorResponse.responseCode}`),
      {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
      });
    }
  }, [csvUploadErrorResponse]);

  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponse.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponse.responseMessage}`,
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else if (
        csvUploadResponse.responseCode === "SUCCESS_WITH_ROW_FAILURE"
      ) {
        toast.warning(
          `${t(`${csvUploadResponse.responseCode + "\n"}`)}`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
        toast.warning(
          <a href={`${csvUploadResponse.errorFileName}`} target="_blank">
            {" "}
            Download Error File{" "}
          </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else if (
        csvUploadResponse.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE"
      ) {
        toast.success(
          `${t(`${csvUploadResponse.responseCode}`) +
          " " +
          csvUploadResponse.failedData.toString()
          }`,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(t(`${csvUploadResponse.responseCode}`),
        {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      }
      refreshHandler();
    }
  }, [csvUpload]);

  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if(fileSizeInMB>40){
      return toast.warning("File size should be less than or equal to 40 MB",
      {
        autoClose: false,
            closeOnClick: false,
            draggable: false,
      })
    }
  //  if(myFiles[0].type!="text/csv" && myFiles[0].type!="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
  //   return toast.warning("Only CSV and EXCEL is supported")

  //  }
  if(myFiles[0].type!="text/csv" ){
    setVisible(!visible)

      return toast.error("Only CSV file is supported",
      {
        autoClose: false,
            closeOnClick: false,
            draggable: false,
      })

   }
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({ type: UPLOAD_PERSONNEL_CSV_DATA, payload: uploadBody });
    dispatch({
      type: "personnel/personnelCSVUploadInitiate",
      payload: { csvUpload: false, loader: true },
    });
  };

  const removeColumn = [
    "personnel_id",
   
    "channel",
    "sku",
    "sku_name",
    "node"
  ];


  let perosnnelGrid =
    personnelDataDetails &&
    personnelDataDetails.length > 0 &&
    Object.keys(personnelDataDetails[0]).filter((item, i) => {
      return !(
        removeColumn.includes(item)
      );
    });
    if (perosnnelGrid.length) {
      let elementToBeDeleted = ["channel_name","node_code","sku_code",];
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        const indexToDelete = perosnnelGrid.indexOf(elementToBeDeleted[i]);
        if (indexToDelete !== -1) {
          perosnnelGrid.splice(indexToDelete, 1);
        }
      }
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        perosnnelGrid.unshift(elementToBeDeleted[i]);
      }
    }
    if (perosnnelGrid.length) {
      let elementToBeDeleted = ["created_at","created_by","modified_at","modified_by"];
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        const indexToDelete = perosnnelGrid.indexOf(elementToBeDeleted[i]);
        if (indexToDelete !== -1) {
          perosnnelGrid.splice(indexToDelete, 1);
        }
      }
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        perosnnelGrid.push(elementToBeDeleted[i]);
      }
    }
    

  let dataColumnDetails =
    personnelDataDetails &&
    personnelDataDetails.length > 0 &&
    Object.keys(personnelDataDetails[0]).filter((item, i) => {
      return !removeColumn.includes(item);

    });

    let addRemoveTableDataColumn = Array.isArray(dataColumnDetails)
    ? dataColumnDetails.map(item =>
      item.includes("sku_code")
          ? productConfiguration["base"] + " " + t("labelCode")
          : item.includes("sku_name")
          ? productConfiguration["base"] + " " + t("labelName")
          : item.includes("node_code") 
          ? locationConfiguration["base"] + " " + t("labelCode")
          : item.includes("node") 
          ? locationConfiguration["base"] + " " + t("labelName")
          : item.includes("emaildemandPlannerHierarchyLevel") 
          ? demandPlannerConfiguration[item.replace(/email/g, "")] + " " + "Email" + " " + "Address"
          : item.includes("emailsalesmanHierarchyLevel") 
          ? salesmanConfiguration[item.replace(/email/g, "")] + " " + "Email" + " " + "Address"
          : item.includes("demandPlannerHierarchyLevel")
          ? demandPlannerConfiguration[item]
          : item.includes("salesmanHierarchyLevel") 
          ? salesmanConfiguration[item]
        : item
    )
    : [];
    

  useEffect(() => {
    const storedCheckboxId = sessionStorage.getItem("personal");
    if (storedCheckboxId) {
      const parsedCheckboxId = JSON.parse(storedCheckboxId);
      setAllCheckboxId(parsedCheckboxId);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    const checkboxId = e.target.id;
    const index = addRemoveTableDataColumn.indexOf(checkboxId);
    setAllCheckboxId((allCheckboxId) => {
      if (!e.target.checked) {
        return [...allCheckboxId, dataColumnDetails[index]];
      } else {
        if (allCheckboxId.length == 1) {
          handleDefault();
        }
        return allCheckboxId.filter((ID) => ID !== dataColumnDetails[index]);
      }
    });
  };

  const handleDefault = () => {
    setAllCheckboxId([]);
    sessionStorage.setItem("personal", JSON.stringify([]));
  };

  const unCheckDefault = () => {
    setAllCheckboxId(Object.values(dataColumnDetails));
    sessionStorage.setItem('personal', JSON.stringify(Object.values(dataColumnDetails)));
  }

  if (!updateflag) {
    const localdata = JSON.parse(localStorage.getItem('personelallcheckboxId'));
 
    if(localdata!=null){
      perosnnelGrid = Object.values(perosnnelGrid).filter(
        (element) => !localdata.includes(element)
      );
    }

  }
  function handleHideandShow() {
    setNewallCheckboxId(allCheckboxId);
    localStorage.setItem('personelallcheckboxId', JSON.stringify(allCheckboxId));
    if (perosnnelGrid.length) {
      setUpdateflag(true);
      if (childData.length) {
        perosnnelGrid = childData;
      }
      perosnnelGrid = perosnnelGrid.filter(
        (element) => !allCheckboxId.includes(element)
      );

    }
  }



  if (entitiesName.includes('personal') && allCheckboxId.length) {
    sessionStorage.setItem("personal", JSON.stringify(allCheckboxId))
  }

  const handleHideShowList = (array) => {
    setHideShowList(array);
  }
  if(hideShowList.length){
    addRemoveTableDataColumn = hideShowList;
  }

  const handleChildData = (array) => {
    setChildData(array);
  };
  if (childData.length) {
    dataColumnDetails = childData;
  }
  const handleSearchItem = (data) => {
    const searchTerm = data.replace(/ /g, "_").toLowerCase();
    const matchFound = addRemoveTableDataColumn.some((item) =>
      item.includes(searchTerm)
    );
    if (matchFound) {
      setSearchColumns(data);
    }
  };

  if (searchColumns.length) {
    addRemoveTableDataColumn = addRemoveTableDataColumn.filter((item) =>
      item.includes(searchColumns.replace(/ /g, "_").toLowerCase())
    );
  }

  // if (perosnnelGrid.length) {
  //   if (childData.length) {
  //     perosnnelGrid = childData;
  //   }
  //   perosnnelGrid = perosnnelGrid.filter(
  //     (element) => !allCheckboxId.includes(element)
  //   );
  // }
  
  if (childData.length) {
    const localdata = JSON.parse(localStorage.getItem('personelallcheckboxId'));
    if(localdata!=null){
      perosnnelGrid = Object.values(childData).filter(
      (element)=> !localdata.includes(element)
      );
    } 
  }

  return (
    <>
      {isLoading && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Personnel")}</h3>
          <Breadcrumb list={[t("Entities Data"), t("Personnel")]} />
          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                setSearchField={setSearchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                rowLength={rowLength}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PERSONNEL}/csv?tenant_id=${tenantId}&bu_id=${buId}&sortFieldStringWithASCOrDESC=${sortValue}&file_type=csv&searchValue=${searchField}&notRequiredColumns=${strNRC}`}
                dataList={personnelDetails ?? []}
                downloadCsvApi={downloadCsvApi}
                downloadName={"personnel.csv"}
                csvUploadPermission={addEditPermission}
                entityName={"personnel"}
                addRemoveTableDataColumn={addRemoveTableDataColumn ?? []}
                onCheckboxChange={handleCheckboxChange}
                sendDataToParent={handleChildData}
                allCheckboxId={allCheckboxId}
                setAllCheckboxId={setAllCheckboxId}
                handleSearchItem={handleSearchItem}
                ondefaultbutton={handleDefault}
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                hierarchyName={hierarchyName}
                hierarchyNameExtra={hierarchyNameExtra}
                handleHideandShow={handleHideandShow}
                unCheckDefault={unCheckDefault}
                dataColumnDetails = {dataColumnDetails}
                dataForHideShowList = {handleHideShowList}
              />
            </div>
            <div className="client-listing-grid">
              {" "}
              <PersonnelGrid
                personnelData={personnelDetails}
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                isLoading={isLoading}
                componentName={"personal"}
                perosnnelGrid={
                  updateflag
                    ? (Array.isArray(perosnnelGrid) ? perosnnelGrid.filter((element) => !newallCheckboxId.includes(element)) : [])
                    : (Array.isArray(perosnnelGrid) ? perosnnelGrid : [])
                }
              />
            </div>
          </div>
        </section>

        {visible && (
          <Dialog id="window">
            <span className="span1">{t("Upload")}</span>
            <AiOutlineCloseCircle
              onClick={toggleDialog}
              className="k-icon k-btn1"
            />
            <DialogActionsBar>
              <div className="border2">
                <div className="div2">
                  <section className="container">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input
                        {...getInputProps()}
                        accept={".csv"}
                        type="file"
                        name="file"
                      />
                      <h4 className="kh4">Select a CSV file to upload</h4>
                      <p className="kp m-0">and drag or drop it here</p>
                      <p className="kp date_format_text mt-2">
                        <b>* -</b> denotes mandatory columns
                      </p>
                    </div>
                    {myFiles[0] && (
                      <aside>{myFiles[0] ? <ul>{files}</ul> : ""}</aside>
                    )}

                    {errorCSV && (
                      <Error className="dialog-error">{t(errorCSV)}</Error>
                    )}
                  </section>
                </div>
              </div>
            </DialogActionsBar>

            <DialogActionsBar>
              <div className="custom-k-dialog-buttongroup">
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base download-template-button"
                  onClick={() => {
                    downloadCsvApi({
                      url: `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PERSONNEL}/template?tenant_id=${tenantId}&bu_id=${buId}`,
                      fileName: "personnel-template.csv",
                    });
                  }}
                >
                  {t("Download Template")}
                  <span className="k-icon k-i-download"></span>
                </button>
                <button
                  className={`k-button k-button-md k-rounded-md k-button-solid k-button-solid-base ${isEmpty(files) && "k-disabled"
                    } upload-csv-button-dialog`}
                  onClick={handleCSVUpload}
                >
                  {t("Upload")}
                </button>
              </div>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default List;
