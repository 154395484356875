import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import TableGrid from "../../../../components/tableGrid";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import "@progress/kendo-theme-default/dist/all.css";
import { get } from "lodash";
import {filesize} from 'filesize'

import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_FORECAST,
  UPLOAD_FORECAST_CSV_DATA,
  GET_ALL_SNOPS,
} from "../../../../store/types";
import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Breadcrumb from "../../../../components/breadcrumb";
import UploadDialog from "../../../../components/uploadDialog";
import { downloadCsvApi } from "../../../../apis";
import { csvFileReader } from "../../../../utils/csvFileReader";
import moment from "moment";
import { entitiesName, permission } from "../../../../utils/Constants";
import { useNumericFormat } from "react-number-format";
import ProcessingLoader from "../../../../components/processingLoader/";
import Select from "react-select";
import { use } from "i18next";

const List = (props) => {
  const { t } = useTranslation();
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).locale
    : {};
  const forecastConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).forecast
    : {};
  const locationConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities
      .locationHierarchy
    : {};
  const productConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).entities
      .productHierarchy
    : {};
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL = process.env.REACT_APP_SCAI_API_BASEURL_FORECAST;

  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(
    permission.forecastSummaryAddEdit
  );
  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [snopId, setSnopId] = useState("");
  const [errorCSV, setErrorCSV] = useState("");
  const [allCheckboxId, setAllCheckboxId] = useState([]);
  const [childData, setChildData] = useState([]);
  const [hideShowList, setHideShowList] = useState([]);
  const [updateflag, setUpdateflag] = useState(false);
  const [newallCheckboxId, setNewallCheckboxId] = useState([]);
  const [searchColumns, setSearchColumns] = useState("");
  const notRequiredColumns = JSON.parse(sessionStorage.getItem('forecast'));
  let strNRC;
  if (notRequiredColumns != null) {
    strNRC = notRequiredColumns.join(",");
  }


  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file]);
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));
  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV("");
    }
  }, [visible]);

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    tenantId: tenantId,
    snopId: snopId,
  };

  useEffect(() => {
    dispatch({
      type: GET_ALL_SNOPS,
    });
  }, [dispatch]);

  useEffect(() => {
    if (snopId && !refresh) {
      dispatch({ type: GET_ALL_FORECAST, payload: body });
    }
  }, [rowLength, pageIndex, sortValue, snopId]);

  const handleSearchSubmit = () => {
    setPageIndex(1);
    dispatch({ type: GET_ALL_FORECAST, payload: { ...body, pageIndex: 1 } });
  };
  const handleAdvanceSearch = (data) => {
    body.advanceSearchValue = data;
    body.buttonStatus = true;
    setPageIndex(1);
    dispatch({
      type: GET_ALL_FORECAST,
      payload: { ...body, pageIndex: 1 },
    });
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({
      type: GET_ALL_FORECAST,
      payload: { ...body, pageIndex: 1 },
    });
  };
  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_FORECAST, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

  const {
    ForecastList,
    csvUploadResponseData,
    csvUploadError,
    csvUpload,
    snopList,
    loader,
  } = useSelector((state) => state.ForecastSlice);
  let snopStartDate = '';
  let snopEndDate = '';
  const snopListData =
    snopList &&
    snopList.map((item) => {
      const items = { ...item };
      items.value = item?.snop_id.toString();
      items.label = item?.snop_name;
      // snopStartDate = item?.from_date;
      // snopEndDate = item?.to_date;

      return items;
    });
    const filteredSnopId=snopListData&& snopListData.length &&snopListData.filter(item=>item.snop_id===snopId)
    snopStartDate=filteredSnopId&& filteredSnopId.length && filteredSnopId[0].from_date
    snopEndDate=filteredSnopId&& filteredSnopId.length && filteredSnopId[0].to_date

  const entities = get(ForecastList, "entities", []);
  const totalElement = get(ForecastList, "totalElements", "");
  const pageSize = get(ForecastList, "pageSize", "");
  const { format } = useNumericFormat({
    decimalSeparator: localeConfiguration["decimalSeparator"],
    thousandsGroupStyle: localeConfiguration["thousandsGroupStyle"],
    thousandSeparator: localeConfiguration["thousandSeparator"],
    decimalScale: 2,
  });
  const entitiesList =
    entities &&
    entities.map((item) => {
      const items = { ...item };
      items.period = moment(item.period).format(
        localeConfiguration["dateFormat"].toUpperCase()
      );
      items.statistical_forecast_volume = format(
        item?.statistical_forecast_volume?.toFixed(2) || ""
      );
      items.statistical_forecast_value = format(
        item?.statistical_forecast_value?.toFixed(2) || ""
      );
      items.operational_forecast_volume = format(
        item?.operational_forecast_volume?.toFixed(2) || ""
      );
      items.operational_forecast_value = format(
        item?.operational_forecast_value?.toFixed(2) || ""
      );
      items.sales_forecast_volume = format(
        item?.sales_forecast_volume?.toFixed(2) || ""
      );
      items.sales_forecast_value = format(
        item?.sales_forecast_value?.toFixed(2) || ""
      );
      items.unconstrained_forecast_volume = format(
        item?.unconstrained_forecast_volume?.toFixed(2) || ""
      );
      items.unconstrained_forecast_value = format(
        item?.unconstrained_forecast_value?.toFixed(2) || ""
      );
      items.channel_name = (item?.channel && item?.channel?.channel_name) ?? "";
      items.sku_name = item?.sku?.sku_name ?? "";
      items.sku_code = item?.sku?.sku_code ?? "";
      items.node_code = item?.node?.node_code ?? "";
      items.node = item?.node?.node ?? "";
      return items;
    });
  const removeColumn = [

    "id",
   
    "channel",
    "sku",
    "period"
  ];
  let dataColumn =
    entitiesList &&
    entitiesList.length > 0 &&
    Object.keys(entitiesList[0]).filter((item) => {
      return !removeColumn.includes(item);
    });
    if (dataColumn.length) {
      let elementToBeDeleted = ["channel_name","node","node_code","sku_name","sku_code","date"];
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        const indexToDelete = dataColumn.indexOf(elementToBeDeleted[i]);
        if (indexToDelete !== -1) {
          dataColumn.splice(indexToDelete, 1);
        }
      }
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        dataColumn.unshift(elementToBeDeleted[i]);
      }
    }
  let dataColumnDetails =
    entitiesList &&
    entitiesList.length > 0 &&
    Object.keys(entitiesList[0]).filter((item) => {
      return !removeColumn.includes(item);
    });

    let addRemoveTableDataColumn = Array.isArray(dataColumnDetails)
    ? dataColumnDetails.map(item =>      
          item.toUpperCase() == "SKU_CODE" &&
          productConfiguration
        ? productConfiguration["base"] +
          " " +
          t("labelCode")
        : item.toUpperCase() == "SKU_NAME" &&
          productConfiguration
        ? productConfiguration["base"] + " " + t("labelName")
        : item.toUpperCase() == "NODE_CODE" &&
          locationConfiguration
        ? locationConfiguration["base"] + " " + t("labelCode")
        : item.toUpperCase() == "NODE" &&
          locationConfiguration
        ? locationConfiguration["base"] 
        : item
    )
    : [];

  useEffect(() => {
    const storedCheckboxId = sessionStorage.getItem('forecast');
    if (storedCheckboxId) {
      const parsedCheckboxId = JSON.parse(storedCheckboxId);
      setAllCheckboxId(parsedCheckboxId);
    }
  }, []);


  const handleCheckboxChange = (e) => {
    const checkboxId = e.target.id;
    const index = addRemoveTableDataColumn.indexOf(checkboxId);
    setAllCheckboxId(allCheckboxId => {
      if (!e.target.checked) {
        return [...allCheckboxId, dataColumnDetails[index]];
      } else {
        if (allCheckboxId.length == 1) {
          setAllCheckboxId([]);
          sessionStorage.setItem('forecast', JSON.stringify([]));
        }
        return allCheckboxId.filter(ID => ID !== dataColumnDetails[index]);
      }

    });
  };

  const handleDefault = () => {
    setAllCheckboxId([]);
    sessionStorage.setItem('forecast', JSON.stringify([]));
  };
  const unCheckDefault = () => {
    setAllCheckboxId(Object.values(dataColumnDetails));
    sessionStorage.setItem('forecast', JSON.stringify(Object.values(dataColumnDetails)));
  }
  if (!updateflag) {
    const localdata = JSON.parse(localStorage.getItem('forecastallcheckboxId'));
    // console.log("localdata",localdata);
    if (localdata != null) {
      dataColumn = Object.values(dataColumn).filter(
        (element) => !localdata.includes(element)
      );
    }
  }
  function handleHideandShow() {
    setNewallCheckboxId(allCheckboxId);
    localStorage.setItem('forecastallcheckboxId', JSON.stringify(allCheckboxId));
    if (dataColumn.length) {
      setUpdateflag(true);
      if (childData.length) {
        dataColumn = childData;
      }
      dataColumn = dataColumn.filter(
        (element) => !allCheckboxId.includes(element)
      );
      // console.log("handleHideandShow", dataColumn);
    }
  }

  if (entitiesName.includes('forecast') && allCheckboxId.length) {
    sessionStorage.setItem("forecast", JSON.stringify(allCheckboxId))
  }

  const handleHideShowList = (array) => {
    setHideShowList(array);
  }
  if(hideShowList.length){
    addRemoveTableDataColumn = hideShowList;
  }

  const handleChildData = (array) => {
    setChildData(array);
  };
  if (childData.length) {
    dataColumnDetails = childData;
  }
  const handleSearchItem = (data) => {
    const searchTerm = data.replace(/ /g, "_").toLowerCase();
    const matchFound = addRemoveTableDataColumn.some((item) =>
      item.includes(searchTerm)
    );
    if (matchFound) {
      setSearchColumns(data);
    }
  };

  if (searchColumns.length) {
    addRemoveTableDataColumn = addRemoveTableDataColumn.filter((item) =>
      item.includes(searchColumns.replace(/ /g, "_").toLowerCase())
    );
  }
  // if (dataColumn.length) {
  //   if (childData.length) {
  //     dataColumn = childData;
  //   }
  //   dataColumn = dataColumn.filter(
  //     (element) => !allCheckboxId.includes(element)
  //   );
  // }

  const customColumnHeaders = () => {
    if (entitiesList && entitiesList.length > 0) {
      return [
         { replaceWith: "sku_code", newLabel: productConfiguration ? productConfiguration["base"] + " " + t("labelCode") : t("sku_code") },
        { replaceWith: "sku_name", newLabel: productConfiguration   ? productConfiguration["base"]+ " " + t("labelName") : t("sku_name") },
        
        {
          replaceWith: "node_code",
          newLabel: locationConfiguration
            ? locationConfiguration["base"] + " " + t("labelCode")
            : t("node_code"),
        },
        {
          replaceWith: "node",
          newLabel: locationConfiguration
            ? locationConfiguration["base"]
            : t("sku_code"),
        },
      ];
    } else {
      return [];
    }
  };
 

  useEffect(() => {
    if (csvUploadError) {
      setVisible(false);
      toast.error(
        t(
          `${
          
            csvUploadError.responseData.message
          }`
        ),
        {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        }
      );
    }
  }, [csvUploadError]);
  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponseData.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponseData.responseMessage}`,
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_ROW_FAILURE"
      ) {
        toast.warning(
          `${t(`${csvUploadResponseData.responseCode + "\n"}`)}`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
        toast.warning(
          <a href={`${csvUploadResponseData.errorFileName}`} target="_blank">
            {" "}
            Download Error File{" "}
          </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE"
      ) {
        toast.success(
          `${t(`${csvUploadResponseData.responseCode}`) +
          " " +
          csvUploadResponseData.failedData.toString()
          }`,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(t(`${csvUploadResponseData.responseCode}`),
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      }
      refreshHandler();
    }
  }, [csvUpload]);
  const toggleDialog = () => {
    dispatch({
      type: "ForecastSlice/ForecastCSVUploadInitiate",
      payload: { csvUpload: false, loader: false },
    });
    setVisible(!visible);
  };
  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if(fileSizeInMB>40){
      return toast.warning("File size should be less than or equal to 40 MB",
      {
        autoClose: false,
            closeOnClick: false,
            draggable: false,
      })
    }
  //  if(myFiles[0].type!="text/csv" && myFiles[0].type!="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
  //   return toast.warning("Only CSV and EXCEL is supported")

  //  }
  if(myFiles[0].type!="text/csv" ){
    setVisible(!visible)

      return toast.error("Only CSV file is supported",
      {
        autoClose: false,
            closeOnClick: false,
            draggable: false,
      })

   }
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
      snopId: snopId,
    };
    dispatch({
      type: UPLOAD_FORECAST_CSV_DATA,
      payload: uploadBody,
    });
    dispatch({
      type: "ForecastSlice/ForecastCSVUploadInitiate",
      payload: { csvUpload: false, loader: true },
    });
  };

  if (childData.length) {
    const localdata = JSON.parse(localStorage.getItem('forecastallcheckboxId'));
    if(localdata!=null){
      dataColumn = Object.values(childData).filter(
      (element)=> !localdata.includes(element)
      );
    } 
  }

 if (dataColumn.length) {
    let elementToBeDeleted = ["integration_status", "processed_status", "processed_date"];
    for (let i = 0; i < elementToBeDeleted.length; i++) {
      const indexToDelete = dataColumn.indexOf(elementToBeDeleted[i]);
      if (indexToDelete !== -1) {
        dataColumn.splice(indexToDelete, 1);
      }
    }
    for (let i = 0; i < elementToBeDeleted.length; i++) {
      dataColumn.push(elementToBeDeleted[i]);
    }
  }
 
  
  return (
    <>
      {loader && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Forecast")}</h3>
          <Breadcrumb list={[t("Transaction Data"), t("Forecast")]} />

          <div className="client-listing-content">
            <div className="snop-listing-input-box mb-3">
              <Select
                classNamePrefix="mySelect"
                className="client-creation-select"
                name="snop"
                id="snop"
                placeholder="Select Snop"
                label="Select Snop"
                options={snopListData ?? []}
                value={snopListData.filter((val) => val.value === snopId)[0]}
                onChange={(value) => setSnopId(value.value)}
              />
            </div>
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                setSearchField={setSearchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/csv?tenant_id=${tenantId}&bu_id=${buId}&snop_id=${snopId}&sortFieldStringWithASCOrDESC=${sortValue}&file_type=csv&searchValue=${searchField}&notRequiredColumns=${strNRC}`}
                dataList={entitiesList}
                disabled={!snopId}
                downloadCsvApi={downloadCsvApi}
                downloadName={"forecast.csv"}
                rowLength={rowLength}
                csvUploadPermission={addEditPermission}
                entityName={"forecast"}
                addRemoveTableDataColumn={addRemoveTableDataColumn ?? []}
                onCheckboxChange={handleCheckboxChange}
                sendDataToParent={handleChildData}
                allCheckboxId={allCheckboxId}
                setAllCheckboxId={setAllCheckboxId}
                handleSearchItem={handleSearchItem}
                ondefaultbutton={handleDefault}
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                handleHideandShow={handleHideandShow}
                unCheckDefault={unCheckDefault}
                dataColumnDetails = {dataColumnDetails}
                dataForHideShowList = {handleHideShowList}
                componentName={"forecast"}
                snopId={snopId}

              />
            </div>
            <div className="client-listing-grid">
              {" "}
              <TableGrid
                dataRows={entitiesList ?? []}
                dataColumn={
                  updateflag
                    ? (Array.isArray(dataColumn) ? dataColumn.filter((element) => !newallCheckboxId.includes(element)) : [])
                    : (Array.isArray(dataColumn) ? dataColumn : [])
                }
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                width="auto"
                replaceColumnLevels={customColumnHeaders()}
                isLoading={loader}
                componentName={"forecast"}
              />
            </div>
          </div>
        </section>

        {visible && (
          <UploadDialog
            templateUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/template?tenant_id=${tenantId}&bu_id=${buId}&snop_id=${snopId}`}
            toggleDialog={toggleDialog}
            handleCSVUpload={handleCSVUpload}
            myFiles={myFiles}
            listing={files}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            downloadCsvApi={downloadCsvApi}
            templateName={"forecast-template.csv"}
            errorCSV={errorCSV}
            dateFormat={localeConfiguration["dateFormat"]}
          />
        )}
      </div>
    </>
  );
};

export default List;
