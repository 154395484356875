import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import TableGrid from "../../../../components/tableGrid";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import "@progress/kendo-theme-default/dist/all.css";
import {filesize} from 'filesize'
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_NETWORKS,
  UPLOAD_NETWORK_CSV_DATA,
} from "../../../../store/types";
import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Breadcrumb from "../../../../components/breadcrumb";
import UploadDialog from "../../../../components/uploadDialog";
import { downloadCsvApi } from "../../../../apis";
import { csvFileReader } from "../../../../utils/csvFileReader";
import moment from "moment";
import { useNumericFormat } from "react-number-format";
import { entitiesName, permission } from "../../../../utils/Constants";
import ProcessingLoader from '../../../../components/processingLoader/'
import { use } from "i18next";

const List = (props) => {
  const { t } = useTranslation();
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;  
  const SCAI_API_BASEURL = process.env.REACT_APP_SCAI_API_BASEURL_NETWORK;

  const user = JSON.parse(localStorage.getItem("user"));
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).locale : {};
  const locationConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).entities.locationHierarchy : {};
  const productConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).entities.productHierarchy : {};
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(permission.networkAddEdit);
  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");
  const [allCheckboxId, setAllCheckboxId] = useState([]);
  const [childData, setChildData] = useState([]);
  const [hideShowList, setHideShowList] = useState([]);
  const [searchColumns, setSearchColumns] = useState("");
  const [updateflag, setUpdateflag] = useState(false);
  const [newallCheckboxId, setNewallCheckboxId] = useState([]);
  const notRequiredColumns = JSON.parse(sessionStorage.getItem('network'));
  let strNRC;
  if (notRequiredColumns != null) {
    strNRC = notRequiredColumns.join(",");
  }

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file])
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV("");
    }
  }, [visible]);

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const toggleDialog = () => {
    dispatch({ type: "network/networkCSVUploadInitiate", payload: { csvUpload: false, loader: false } });
    setVisible(!visible);
  };

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    tenantId: tenantId,
  };

  useEffect(() => {
    if (!refresh) {
      dispatch({ type: GET_ALL_NETWORKS, payload: body });
    }
  }, [dispatch, pageIndex, sortValue, rowLength]);

  const handleSearchSubmit = () => {
    setPageIndex(1);
    dispatch({ type: GET_ALL_NETWORKS, payload: { ...body, pageIndex: 1 } });
  };

  const handleAdvanceSearch = (data) => {
    body.advanceSearchValue = data;
    body.buttonStatus = true;
    setPageIndex(1);
    dispatch({ type: GET_ALL_NETWORKS, payload: { ...body, pageIndex: 1 } });
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({ type: GET_ALL_NETWORKS, payload: { ...body, pageIndex: 1 } });
  };

  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_NETWORKS, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

  const { networkList, csvUploadResponseData, csvUploadError, csvUpload, loader } =
    useSelector((state) => state.NetworkReducer);
  const networkEntities = get(networkList, "entities", "");
  const totalElement = get(networkList, "totalElements", "");
  const pageSize = get(networkList, "pageSize", "");

  const { format } = useNumericFormat({
    decimalSeparator: localeConfiguration["decimalSeparator"],
    thousandsGroupStyle: localeConfiguration["thousandsGroupStyle"],
    thousandSeparator: localeConfiguration["thousandSeparator"],
    decimalScale: 2
  });

  const networkDetails =
    networkEntities &&
    networkEntities.map((item) => {
      const items = { ...item };
      if (item.contract_end_date) {
        items.contract_end_date = moment(item.contract_end_date).format(localeConfiguration["dateFormat"].toUpperCase());
      } else {
        items.contract_end_date = "";
      }
      
      if (item.contract_start_date) {
        items.contract_start_date = moment(item.contract_start_date).format(localeConfiguration["dateFormat"].toUpperCase());
      } else {
        items.contract_start_date = "";
      }
      
      if (item.contract_sunset_date) {
        items.contract_sunset_date = moment(item.contract_sunset_date).format(localeConfiguration["dateFormat"].toUpperCase());
      } else {
        items.contract_sunset_date = "";
      }
      items.start_node = item?.start_node?.node ?? ''
      items.mode = item?.mode?.mode ?? ''
      items.mode_type = item?.mode?.mode_type ?? ''
      // items.item_code = item?.sku?.sku_code ?? ''
      items.sku_name = item?.sku?.sku_name ?? ''
      items.end_node = item?.end_node?.node ?? ''
      items.asset_capacity_unit = format(item.asset_capacity_unit.toFixed(2));
      items.order_cycle_time = format(item.order_cycle_time.toFixed(2));
      items.fixed_cost = format(item.fixed_cost.toFixed(2));
      items.variable_cost = format(item.variable_cost.toFixed(2));
      items.contract_life_cycle_status = item.contract_life_cycle_status ? t("active") : t("inactive");
      items.is_normal = item?.mode?.is_normal ?? ''
      items.channel_name = (item?.channel && item?.channel?.channel_name) ?? ''
      return items;
    });

  const removeColumn = ["network_id","rm",  "sku", "id", "sku_network_mapping_id", "channel","sku_name","is_normal"];
  let dataColumn =
    networkDetails &&
    networkDetails.length > 0 &&
    Object.keys(networkDetails[0]).filter((item) => {
      return !removeColumn.includes(item);
    });
    if (dataColumn.length) {
      let elementToBeDeleted = ["created_at","created_by","modified_at","modified_by"];
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        const indexToDelete = dataColumn.indexOf(elementToBeDeleted[i]);
        if (indexToDelete !== -1) {
          dataColumn.splice(indexToDelete, 1);
        }
      }
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        dataColumn.push(elementToBeDeleted[i]);
      }
    }
  let dataColumnDetails =
    networkDetails &&
    networkDetails.length > 0 &&
    Object.keys(networkDetails[0]).filter((item) => {
      return !removeColumn.includes(item);
    });

    let addRemoveTableDataColumn = Array.isArray(dataColumnDetails)
    ? dataColumnDetails.map(item =>      
          item.toUpperCase() == "SKU_CODE" &&
          productConfiguration
        ? productConfiguration["base"] +
          " " +
          t("labelCode")
        : item.toUpperCase() == "SKU_NAME" &&
          productConfiguration
        ? productConfiguration["base"] + " " + t("labelName")
        : item.toUpperCase() == "START_NODE" &&
          locationConfiguration
        ? t("labelStart") + " " + locationConfiguration["base"]
        : item.toUpperCase() == "END_NODE" &&
          locationConfiguration
        ? t("labelEnd") + " " + locationConfiguration["base"] 
        : item
    )
    : [];

  useEffect(() => {
    const storedCheckboxId = sessionStorage.getItem('network');
    if (storedCheckboxId) {
      const parsedCheckboxId = JSON.parse(storedCheckboxId);
      setAllCheckboxId(parsedCheckboxId);
    }
  }, []);


  const handleCheckboxChange = (e) => {
    const checkboxId = e.target.id;
    const index = addRemoveTableDataColumn.indexOf(checkboxId);
    setAllCheckboxId(allCheckboxId => {
      if (!e.target.checked) {
        return [...allCheckboxId, dataColumnDetails[index]];
      } else {
        if (allCheckboxId.length == 1) {
          setAllCheckboxId([]);
          sessionStorage.setItem('network', JSON.stringify([]));
        }
        return allCheckboxId.filter(ID => ID !== dataColumnDetails[index]);
      }

    });
  };

  const handleDefault = () => {
    setAllCheckboxId([]);
    sessionStorage.setItem('network', JSON.stringify([]));
  };
  const unCheckDefault = () => {
    setAllCheckboxId(Object.values(dataColumnDetails));
    sessionStorage.setItem('network', JSON.stringify(Object.values(dataColumnDetails)));
  }
  if (!updateflag) {
    const localdata = JSON.parse(localStorage.getItem('networkallcheckboxId'));
    // console.log("localdata",localdata);
    if (localdata != null) {
      dataColumn = Object.values(dataColumn).filter(
        (element) => !localdata.includes(element)
      );
    }
  }
  function handleHideandShow() {
    setNewallCheckboxId(allCheckboxId);
    localStorage.setItem('networkallcheckboxId', JSON.stringify(allCheckboxId));
    if (dataColumn.length) {
      setUpdateflag(true);
      if (childData.length) {
        dataColumn = childData;
      }
      dataColumn = dataColumn.filter(
        (element) => !allCheckboxId.includes(element)
      );
      // console.log("handleHideandShow", dataColumn);
    }
  }

  if (entitiesName.includes('network') && allCheckboxId.length) {
    sessionStorage.setItem("network", JSON.stringify(allCheckboxId))
  }

  const handleHideShowList = (array) => {
    setHideShowList(array);
  }
  if(hideShowList.length){
    addRemoveTableDataColumn = hideShowList;
  }

  const handleChildData = (array) => {
    setChildData(array);
  };
  if (childData.length) {
    dataColumnDetails = childData;
  }
  const handleSearchItem = (data) => {
    const searchTerm = data.replace(/ /g, "_").toLowerCase();
    const matchFound = addRemoveTableDataColumn.some((item) =>
      item.includes(searchTerm)
    );
    if (matchFound) {
      setSearchColumns(data);
    }
  };



  if (searchColumns.length) {
    addRemoveTableDataColumn = addRemoveTableDataColumn.filter((item) =>
      item.includes(searchColumns.replace(/ /g, "_").toLowerCase())
    );
  }
  // if (dataColumn.length) {
  //   if (childData.length) {
  //     dataColumn = childData;
  //   }
  //   dataColumn = dataColumn.filter(
  //     (element) => !allCheckboxId.includes(element)
  //   );
  // }

  const customColumnHeaders = () => {
    if (networkDetails && networkDetails.length > 0) {
      return [
        { replaceWith: "sku_code", newLabel: productConfiguration ? productConfiguration["base"] + " " + t("labelCode") : t("sku_code") },
        { replaceWith: "sku_name", newLabel: productConfiguration ? productConfiguration["base"] : t("sku_name") },
        { replaceWith: "start_node", newLabel: locationConfiguration ? t("labelStart") + " " + locationConfiguration["base"] : t("start_node") },
        { replaceWith: "end_node", newLabel: locationConfiguration ? t("labelEnd") + " " + locationConfiguration["base"] : t("end_node") },
      ];
    }
    else {
      return [];
    }
  }

  useEffect(() => {
    if (csvUploadError) {
      setVisible(false);
      toast.error(t(`${csvUploadError.message}`),
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
  }, [csvUploadError]);

  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponseData.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponseData.responseMessage}`,
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_ROW_FAILURE"
      ) {
        toast.warning(
          `${t(`${csvUploadResponseData.responseCode + '\n'}`)
          }`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        )
        toast.warning(
          <a href={`${csvUploadResponseData.errorFileName}`} target="_blank"> Download Error File </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        )
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE"
      ) {
        toast.success(
          `${t(`${csvUploadResponseData.responseCode}`) +
          " " +
          csvUploadResponseData.failedData.toString()
          }`,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(t(`${csvUploadResponseData.responseCode}`),
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      }
      refreshHandler();
    }

  }, [csvUpload]);
  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if(fileSizeInMB>40){
      return toast.warning("File size should be less than or equal to 40 MB",
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      })
    }
  //  if(myFiles[0].type!="text/csv" && myFiles[0].type!="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
  //   return toast.warning("Only CSV and EXCEL is supported")

  //  }
  if(myFiles[0].type!="text/csv" ){
    setVisible(!visible)

      return toast.error("Only CSV file is supported",
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      })

   }
    
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({ type: UPLOAD_NETWORK_CSV_DATA, payload: uploadBody });
    dispatch({ type: "network/networkCSVUploadInitiate", payload: { csvUpload: false, loader: true } });
  };

  if (childData.length) {
    const localdata = JSON.parse(localStorage.getItem('networkallcheckboxId'));
    if(localdata!=null){
      dataColumn = Object.values(childData).filter(
      (element)=> !localdata.includes(element)
      );
    } 
  }
  
  return (
    <>
      {loader && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Network")}</h3>
          <Breadcrumb list={[t("Entities Data"), t("Network")]} />
          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                setSearchField={setSearchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/csv?tenant_id=${tenantId}&bu_id=${buId}&sortFieldStringWithASCOrDESC=${sortValue}&file_type=csv&searchValue=${searchField}&notRequiredColumns=${strNRC}`}
                dataList={networkDetails}
                downloadCsvApi={downloadCsvApi}
                downloadName={"network.csv"}
                rowLength={rowLength}
                csvUploadPermission={addEditPermission}
                entityName={"network"}
                addRemoveTableDataColumn={addRemoveTableDataColumn ?? []}
                onCheckboxChange={handleCheckboxChange}
                sendDataToParent={handleChildData}
                allCheckboxId={allCheckboxId}
                setAllCheckboxId={setAllCheckboxId}
                handleSearchItem={handleSearchItem}
                ondefaultbutton={handleDefault}
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                handleHideandShow={handleHideandShow}
                unCheckDefault={unCheckDefault}
                dataColumnDetails = {dataColumnDetails}
                dataForHideShowList = {handleHideShowList}
              />
            </div>
            <div className="client-listing-grid">
              {" "}
              <TableGrid
                dataRows={networkDetails ?? []}
                dataColumn={
                  updateflag
                    ? (Array.isArray(dataColumn) ? dataColumn.filter((element) => !newallCheckboxId.includes(element)) : [])
                    : (Array.isArray(dataColumn) ? dataColumn : [])
                }
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                isLoading={loader}
                replaceColumnLevels={customColumnHeaders()}
                componentName={"network"}

              />
            </div>

          </div>
        </section>

        {visible && (
          <UploadDialog
            templateUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/template?tenant_id=${tenantId}&bu_id=${buId}`}
            toggleDialog={toggleDialog}
            handleCSVUpload={handleCSVUpload}
            myFiles={myFiles}
            listing={files}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            downloadCsvApi={downloadCsvApi}
            templateName={"network-template.csv"}
            errorCSV={errorCSV}
            dateFormat={localeConfiguration["dateFormat"]}
          />
        )}
      </div>
    </>
  );
};

export default List;
