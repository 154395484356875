import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { isEmpty } from "lodash";
import Loader from "../../../../components/loader";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import Pagination from "../../../../components/tableGrid/pagination";
import { setTableWidth } from "../../../../utils/setTableWidth";
import { BsDownload } from "react-icons/bs";


const SyncstatusGrid = (props) => {

  const { t } = useTranslation();

  const grid = React.useRef(null);

  const productConfiguration = JSON.parse(localStorage.getItem("configuration"))

    ? JSON.parse(localStorage.getItem("configuration")).entities

      .productHierarchy

    : {};

  const localeConfiguration = JSON.parse(localStorage.getItem("configuration"))

    ? JSON.parse(localStorage.getItem("configuration")).locale

    : {};

  const {

    productData,

    refresh,

    pageSize,

    totalElement,

    setPageIndex,

    pageIndex,

    setSortValue,

    isLoading,

  } = props;




  const initialDataState = {

    skip: 0,

  };

  const [dataState, setDataState] = useState(initialDataState);

  const [sortKey, setSortKey] = useState("");

  const [sortDir, setSortDir] = useState(false);




  const noOfPages = Math.ceil(totalElement / pageSize);

  const start = (pageIndex - 1) * pageSize;

  const end = start + pageSize;

  const endCount = end > totalElement ? `- ${totalElement}` : `- ${end}`;

  useEffect(() => {

    setDataState({ ...dataState });

  }, [pageSize, totalElement]);

  useEffect(() => {

    setSortKey("");

  }, [refresh]);

  if (refresh || isLoading) {

    return <Loader />;

  }

  const productDataRows =

    productData &&

    productData.map((item) => {

      const items = { ...item };

      return items;

    });



  const removeColumn = ["fileId", "modifiedAt", "modifiedBy", "tenantId", "buId", "pathOfMainFile", "pathOfErrorFile", "status"];

  let SyncstatusGrid =

    productDataRows &&

    productDataRows.length > 0 &&

    Object.keys(productDataRows[0]).filter((item, i) => {

      return !removeColumn.includes(item);

    });



  const _renderSortIcons = (obj) => {

    return (

      <>

        {sortKey === obj ? (

          sortDir || obj !== sortKey ? (

            <i className="fa fa-arrow-down" aria-hidden="true"></i>

          ) : (

            <i className="fa fa-arrow-up" aria-hidden="true"></i>

          )

        ) : (

          <>

            {" "}

            <i className="fa fa-sort" aria-hidden="true"></i>

          </>

        )}{" "}

      </>

    );

  };


  const ClickableColumn = function (props) {

    var s = props.dataItem[props.field];
    let sSplittedRight;

    if (s != null || s != undefined) {
      console.log("s++",s);
      if (s.includes("/qa")) {
        sSplittedRight = s.split("qa/")[1]?.replace(/-/g, "_");
      }
      else if(s.includes("/vip/entities/prod")){
        sSplittedRight = s.split("vip/entities/prod/")[1]?.replace(/-/g, "_");

      }
      else if(s.includes("/uat")){
        sSplittedRight = s.split("uat/")[1]?.replace(/-/g, "_");
      }
      else {
        sSplittedRight = s.split("dev/")[1]?.replace(/-/g, "_");
      }

    }
    return (

      <td >
        {
          s === null ? <a >NA</a> : <a href={props.dataItem[props.field]} style={{color: '#87CEEB', textDecoration: 'none',textDecoration:'underline'}} >{sSplittedRight}</a>
        }
      </td>
    );
  };



  const gridContainerStyle = {

    display: 'flex',

  };


  const leftGridStyle = {

    flex: '2',

    minWidth: 0,

  };



  const rightGridStyle = {

    flex: '1',

    minWidth: 0,

  };

  return (

    <div className="tablegrid">

      {!isEmpty(productDataRows) ? (

        <>

          <div style={gridContainerStyle}>

            <div style={leftGridStyle}>

              <Grid

                pageable={false}

                sortable={false}

                resizable={true}

                fixedScroll={true}

                data={

                  !isEmpty(productDataRows)

                    ? process(productDataRows, dataState)

                    : null

                }

                {...dataState}

                onDataStateChange={(e) => {

                  setDataState(e.dataState);

                }}

                className={"table-style"}

              >

                {SyncstatusGrid &&

                  SyncstatusGrid?.map((item, idx) => {

                    if (item !== "mainFileName" && item !== "errorFileName") {

                      return (


                        <Column

                          key={idx}

                          title={
                            item.includes("createdAt")  
                            ? t("createdAt")
                            : item.includes("createdBy") 
                            ? t("createdBy")
                            : item.includes("routeType")
                            ? t("routeType")
                            : item.includes("uploadStatus")
                            ? t("uploadStatus")        
                            : t(`${item}`)
                          }
                          field={item}
                          width={setTableWidth(120, grid, SyncstatusGrid)}
                          headerClassName={"column-sorting-icon"}

                        />

                      );

                    }

                  })}

              </Grid>

            </div>

            <div style={rightGridStyle}>

              <Grid

                pageable={false}

                sortable={false}

                resizable={true}

                fixedScroll={true}

                data={productDataRows}

              >

                <Column field="errorFileName" title="Error File"

                  width={setTableWidth(120, grid, SyncstatusGrid)}

                  cell={(props) => (

                    <ClickableColumn  {...props} />

                  )}

                />

                <Column field="mainFileName" title="Uploaded File"

                  width={setTableWidth(120, grid, SyncstatusGrid)}

                  cell={(props) => (

                    <ClickableColumn {...props} />

                  )}

                />

              </Grid>

            </div>

          </div>

          <Pagination

            endCount={endCount}

            page={parseInt(pageIndex)}

            onChange={(e, value) => setPageIndex(value)}

            count={noOfPages}

            showFirstButton={true}

            showLastButton={true}

            totalElement={totalElement}

            start={start}

          />

        </>

      ) : (

        <>

          <div className="text-center">No records found</div>

        </>

      )}

    </div>

  );

};




export default SyncstatusGrid;