import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import TableGrid from "../../../../components/tableGrid";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import "@progress/kendo-theme-default/dist/all.css";
import { get } from "lodash";
import { filesize } from "filesize";

import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_FEATURES,
  UPLOAD_FEATURES_CSV_DATA,
} from "../../../../store/types";
import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Breadcrumb from "../../../../components/breadcrumb";
import UploadDialog from "../../../../components/uploadDialog";
import { downloadCsvApi } from "../../../../apis";
import { csvFileReader } from "../../../../utils/csvFileReader";
import moment from "moment";
import { entitiesName, permission } from "../../../../utils/Constants";
import ProcessingLoader from "../../../../components/processingLoader/";
import { use } from "i18next";

const List = (props) => {
  const { t } = useTranslation();
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).locale
    : {};
  const locationHierarchy = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .locationHierarchy
    : {};
  const productHierarchy = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .productHierarchy
    : {};
    const featureCustomHeader = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.featureCustomHeader
    : {};


  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL = process.env.REACT_APP_SCAI_API_BASEURL_FEATURES;
  const locationConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .locationHierarchy
    : {};
  const productConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .productHierarchy
    : {};
  const featureConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).transactional.features
    : {};
  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(permission.featureAddEdit);
  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");
  const [allCheckboxId, setAllCheckboxId] = useState([]);
  const [childData, setChildData] = useState([]);
  const [hideShowList, setHideShowList] = useState([]);
  const [updateflag, setUpdateflag] = useState(false);
  const [newallCheckboxId, setNewallCheckboxId] = useState([]);
  const [searchColumns, setSearchColumns] = useState("");
  const notRequiredColumns = JSON.parse(sessionStorage.getItem("feature"));
  let strNRC;
  if (notRequiredColumns != null) {
    strNRC = notRequiredColumns.join(",");
  }

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file]);
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));
  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV("");
    }
  }, [visible]);

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    tenantId: tenantId,
  };

  const storedFeatureHeaderString = localStorage.getItem('featureHeader');
  let storedFeatureHeader;
  if(storedFeatureHeaderString !== 'undefined'){
      storedFeatureHeader = JSON.parse(storedFeatureHeaderString);
  }

  useEffect(() => {
    if (!refresh) {
      dispatch({ type: GET_ALL_FEATURES, payload: body });
    }
  }, [dispatch, pageIndex, sortValue, rowLength]);

  const handleSearchSubmit = () => {
    setPageIndex(1);
    dispatch({ type: GET_ALL_FEATURES, payload: { ...body, pageIndex: 1 } });
  };
  const handleAdvanceSearch = (data) => {
    body.advanceSearchValue = data;
    body.buttonStatus = true;
    setPageIndex(1);
    dispatch({
      type: GET_ALL_FEATURES,
      payload: { ...body, pageIndex: 1 },
    });
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({
      type: GET_ALL_FEATURES,
      payload: { ...body, pageIndex: 1 },
    });
  };
  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_FEATURES, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

  const {
    featureList,
    csvUploadResponseData,
    csvUploadError,
    csvUpload,
    loader,
  } = useSelector((state) => state.FeatureReducer);
  const entities = get(featureList, "entities", []);
  const totalElement = get(featureList, "totalElements", "");
  const pageSize = get(featureList, "pageSize", "");
  const entitiesData =
    entities &&
    entities.map((item) => {
      const items = { ...item };
      if (featureConfiguration && Number(featureConfiguration["productLevel"]) == 0 ||  featureConfiguration && featureConfiguration["productLevel"] == null )  {
        items.sku_name = item?.sku?.sku_name ?? ''
        items.sku_code = item?.sku?.sku_code ?? ''
      }
      if (featureConfiguration && Number(featureConfiguration["locationLevel"]) == 0 || featureConfiguration && featureConfiguration["locationLevel"] == null) {
        items.node_code = item?.node?.node_code ?? ''
        items.node = item?.node?.node ?? ''
      }
      if (
        featureCustomHeader &&
        Number(featureCustomHeader?.featureCustomHeaderCount) &&
        Number(featureCustomHeader?.featureCustomHeaderCount) > 0
      ) {
        console.log("iaminsideif");
        for (
          let index = 1;
          index <= featureCustomHeader?.featureCustomHeaderCount;
          index++
        ) {
         console.log("headerrrrrr",index)
          items[featureCustomHeader[`header${index}`]] = item[`header${index}`]
          delete items[`header${index}`]
        }
      }
      items.channel_name = item?.channel?.channel_name ?? "";
      return items;
    });

  const removeColumn = [
    "id",
   
    "sku",
    "channel",
    "pincode",
  ];

  let dataColumn =
    entitiesData &&
    entitiesData.length > 0 &&
    Object.keys(entitiesData[0]).filter((item) => {
      return !removeColumn.includes(item);
    });
    if (dataColumn.length) {
      let elementToBeDeleted = ["channel_name","node","node_code","sku_name","sku_code","end_date","start_date"];
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        const indexToDelete = dataColumn.indexOf(elementToBeDeleted[i]);
        if (indexToDelete !== -1) {
          dataColumn.splice(indexToDelete, 1);
        }
      }
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        dataColumn.unshift(elementToBeDeleted[i]);
      }
    }

    if (dataColumn.length) {
      let elementToBeDeleted = ["created_at","created_by","modified_at","modified_by"];
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        const indexToDelete = dataColumn.indexOf(elementToBeDeleted[i]);
        if (indexToDelete !== -1) {
          dataColumn.splice(indexToDelete, 1);
        }
      }
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        dataColumn.push(elementToBeDeleted[i]);
      }
    }
  let dataColumnDetails =
    entitiesData &&
    entitiesData.length > 0 &&
    Object.keys(entitiesData[0]).filter((item) => {
      return !removeColumn.includes(item);
    });

    // let addRemoveTableDataColumn = Array.isArray(dataColumnDetails)
    // ? dataColumnDetails.map(item =>      
    //   item.includes("product_hierarchy_attribute") && (featureConfiguration && Number(featureConfiguration["productLevel"]) > 0)
    //   ? productConfiguration["productHierarchyLevel" + featureConfiguration["productLevel"]]
    //   : item.includes("sku_code") && productConfiguration
    //   ? productConfiguration["base"] + " " + t("labelCode")
    //   : item.includes("sku_name") && productConfiguration
    //   ? productConfiguration["base"] + " " + t("labelName")
    //   : item.includes("location_hierarchy_attribute") && (featureConfiguration && Number(featureConfiguration["locationLevel"]) > 0)
    //   ? locationConfiguration["locationHierarchyLevel" + featureConfiguration["locationLevel"]]
    //   : item.includes("node_code") && locationConfiguration
    //   ? locationConfiguration["base"] + " " + t("labelCode")
    //   : item.includes("node") && locationConfiguration
    //   ? locationConfiguration["base"]
    //     : item
    // )
    // : [];
let addRemoveTableDataColumn = Array.isArray(dataColumnDetails)
    ? dataColumnDetails.map((item) => {
      if (item && item.includes("feature") && !item.includes("descriptionfeature")) {
        return featureConfiguration[item] || item;
      }else  if (item && item.includes("descriptionfeature")) {
        let featureNumber = item.substring(11);

        return featureConfiguration[featureNumber] + " " + "Description";
      }else{
        return item
      }
    }):[]

  useEffect(() => {
    const storedCheckboxId = sessionStorage.getItem("feature");
    if (storedCheckboxId) {
      const parsedCheckboxId = JSON.parse(storedCheckboxId);
      setAllCheckboxId(parsedCheckboxId);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    const checkboxId = e.target.id;
    const index = addRemoveTableDataColumn.indexOf(checkboxId);
    setAllCheckboxId((allCheckboxId) => {
      if (!e.target.checked) {
        return [...allCheckboxId, dataColumnDetails[index]];
      } else {
        if (allCheckboxId.length == 1) {
          setAllCheckboxId([]);
          sessionStorage.setItem("feature", JSON.stringify([]));
        }
        return allCheckboxId.filter((ID) => ID !== dataColumnDetails[index]);
      }
    });
  };

  const handleDefault = () => {
    setAllCheckboxId([]);
    sessionStorage.setItem("feature", JSON.stringify([]));
  };
  const unCheckDefault = () => {
    setAllCheckboxId(Object.values(dataColumnDetails));
    sessionStorage.setItem(
      "feature",
      JSON.stringify(Object.values(dataColumnDetails))
    );
  };
  if (!updateflag) {
    const localdata = JSON.parse(localStorage.getItem("featureallcheckboxId"));
    // console.log("localdata",localdata);
    if (localdata != null) {
      dataColumn = Object.values(dataColumn).filter(
        (element) => !localdata.includes(element)
      );
    }
  }
  function handleHideandShow() {
    setNewallCheckboxId(allCheckboxId);
    localStorage.setItem("featureallcheckboxId", JSON.stringify(allCheckboxId));
    if (dataColumn.length) {
      setUpdateflag(true);
      if (childData.length) {
        dataColumn = childData;
      }
      dataColumn = dataColumn.filter(
        (element) => !allCheckboxId.includes(element)
      );
    }
  }

  if (entitiesName.includes("feature") && allCheckboxId.length) {
    sessionStorage.setItem("feature", JSON.stringify(allCheckboxId));
  }

  const handleHideShowList = (array) => {
    setHideShowList(array);
  }
  if(hideShowList.length){
    addRemoveTableDataColumn = hideShowList;
  }
  const handleChildData = (array) => {
    setChildData(array);
  };
  if (childData.length) {
    dataColumnDetails = childData;
  }
  const handleSearchItem = (data) => {
    const searchTerm = data.replace(/ /g, "_").toLowerCase();
    const matchFound = addRemoveTableDataColumn.some((item) =>
      item.includes(searchTerm)
    );
    if (matchFound) {
      setSearchColumns(data);
    }
  };

  if (searchColumns.length) {
    addRemoveTableDataColumn = addRemoveTableDataColumn.filter((item) =>
      item.includes(searchColumns.replace(/ /g, "_").toLowerCase())
    );
  }
  // if (dataColumn.length) {
  //   if (childData.length) {
  //     dataColumn = childData;
  //   }
  //   dataColumn = dataColumn.filter(
  //     (element) => !allCheckboxId.includes(element)
  //   );
  // }

  const customColumnHeaders = () => {
    if (entitiesData && entitiesData.length > 0) {
      let customHeaders = [];
      if (
        featureConfiguration &&
        Number(featureConfiguration["productLevel"]) > 0
      ) {
        customHeaders.push({
          replaceWith: "product_hierarchy_attribute",
          newLabel:
            productConfiguration[
              "productHierarchyLevel" + featureConfiguration["productLevel"]
            ],
        });
      } else {
        customHeaders.push({
          replaceWith: "sku_code",
          newLabel: productConfiguration
            ? productConfiguration["base"] + " " + t("labelCode")
            : t("sku_code"),
        });
        customHeaders.push({
          replaceWith: "sku_name",
          newLabel: productConfiguration
            ? productConfiguration["base"]
            : t("sku_name"),
        });
      }
      if (
        featureConfiguration &&
        Number(featureConfiguration["locationLevel"]) > 0
      ) {
        customHeaders.push({
          replaceWith: "location_hierarchy_attribute",
          newLabel:
            locationConfiguration[
              "locationHierarchyLevel" + featureConfiguration["locationLevel"]
            ],
        });
      } else {
        customHeaders.push({
          replaceWith: "node_code",
          newLabel: locationConfiguration
            ? locationConfiguration["base"] + " " + t("labelCode")
            : t("node_code"),
        });
        customHeaders.push({
          replaceWith: "node",
          newLabel: locationConfiguration
            ? locationConfiguration["base"]
            : t("sku_code"),
        });
      }
      customHeaders.push(
        ...Object.keys(entitiesData[0]).reduce((acc, item) => {
          if (item && item.includes("feature") && !item.includes("descriptionfeature")) {
            acc.push({
              replaceWith: item,
              newLabel: featureConfiguration[item] || item,
            });
          }
          if (item && item.includes("descriptionfeature")) {
            let featureNumber = item.substring(11);

            acc.push({
              replaceWith: item,
              newLabel: featureConfiguration[featureNumber] + " " + "Description",
            });
          }
          return acc;
        }, [])
      );

      return customHeaders;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (csvUploadError) {
      setVisible(false);
      toast.error(
        t(
          `${
            csvUploadError?.responseMessage ??
            csvUploadError.message ??csvUploadError?.responseData?.message??
            csvUploadError
          }`
        ),
        {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        }
      );
    }
  }, [csvUploadError]);
  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponseData.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponseData.responseMessage}`,
        {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_ROW_FAILURE"
      ) {
        toast.warning(
          `${t(`${csvUploadResponseData.responseCode + "\n"}`)}`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
        toast.warning(
          <a href={`${csvUploadResponseData.errorFileName}`} target="_blank">
            {" "}
            Download Error File{" "}
          </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE"
      ) {
        toast.success(
          `${
            t(`${csvUploadResponseData.responseCode}`) +
            " " +
            csvUploadResponseData.failedData.toString()
          }`,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(t(`${csvUploadResponseData.responseCode}`),
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      }
      refreshHandler();
    }
  }, [csvUpload]);
  const toggleDialog = () => {
    dispatch({
      type: "feature/featureCSVUploadInitiate",
      payload: { csvUpload: false, loader: false },
    });
    setVisible(!visible);
  };
  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if (fileSizeInMB > 40) {
      return toast.warning("File size should be less than or equal to 40 MB",
      {
        autoClose: false,
            closeOnClick: false,
            draggable: false,
      });
    }
    // if (
    //   myFiles[0].type != "text/csv" &&
    //   myFiles[0].type !=
    //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    // ) {
    //   return toast.warning("Only CSV and EXCEL is supported");
    // }
    if(myFiles[0].type!="text/csv" ){
      setVisible(!visible)
  
        return toast.error("Only CSV file is supported",
        {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        })
  
     }
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({
      type: UPLOAD_FEATURES_CSV_DATA,
      payload: uploadBody,
    });
    dispatch({
      type: "feature/featureCSVUploadInitiate",
      payload: { csvUpload: false, loader: true },
    });
  };

  if (childData.length) {
    const localdata = JSON.parse(localStorage.getItem("featureallcheckboxId"));
    if (localdata != null) {
      dataColumn = Object.values(childData).filter(
        (element) => !localdata.includes(element)
      );
    }
  }
  return (
    <>
      {loader && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Feature")}</h3>
          <Breadcrumb list={[t("Transaction Data"), t("Feature")]} />
          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                setSearchField={setSearchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/csv?tenant_id=${tenantId}&sortFieldStringWithASCOrDESC=${sortValue}&bu_id=${buId}&file_type=csv&searchValue=${searchField}&notRequiredColumns=${strNRC}`}
                dataList={entitiesData}
                downloadCsvApi={downloadCsvApi}
                downloadName={"feature.csv"}
                rowLength={rowLength}
                csvUploadPermission={addEditPermission}
                entityName={"feature"}
                addRemoveTableDataColumn={addRemoveTableDataColumn ?? []}
                onCheckboxChange={handleCheckboxChange}
                sendDataToParent={handleChildData}
                allCheckboxId={allCheckboxId}
                setAllCheckboxId={setAllCheckboxId}
                handleSearchItem={handleSearchItem}
                ondefaultbutton={handleDefault}
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                handleHideandShow={handleHideandShow}
                unCheckDefault={unCheckDefault}
                dataColumnDetails = {dataColumnDetails}
                dataForHideShowList = {handleHideShowList}
                dataRows={entitiesData ?? []}
                storedFeatureHeader={storedFeatureHeader}
                componentNameAddEdit={"Feature"}
              />
            </div>
            <div className="client-listing-grid">
              {" "}
              <TableGrid
                dataRows={entitiesData ?? []}
                dataColumn={
                  updateflag
                    ? (Array.isArray(dataColumn) ? dataColumn.filter((element) => !newallCheckboxId.includes(element)) : [])
                    : (Array.isArray(dataColumn) ? dataColumn : [])
                }
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                width={"auto"}
                replaceColumnLevels={customColumnHeaders()}
                isLoading={loader}
                componentName={"feature"}
              />
            </div>
          </div>
        </section>

        {visible && (
          <UploadDialog
            templateUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/template?tenant_id=${tenantId}&bu_id=${buId}`}
            toggleDialog={toggleDialog}
            handleCSVUpload={handleCSVUpload}
            myFiles={myFiles}
            listing={files}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            downloadCsvApi={downloadCsvApi}
            templateName={"feature-template.csv"}
            errorCSV={errorCSV}
            dateFormat={localeConfiguration["dateFormat"]}
          />
        )}
      </div>
    </>
  );
};

export default List;
